import { createSelector } from '@ngrx/store';
import { IFeature, IFeatureListMultiPrice } from '@proxyclick/data-model/index';
import { IAppState } from '../app-state';

export const TARGET_VERSION = 3;
export type TargetMandatoryFeatureListRef = 'essential_2021_04' | 'premium_2021_04' | 'enterprise_2021_04';
export type TargetOptionalFeatureListRef = 'idMatch_2021_04' | 'wifi_2020_01' | 'acs_2020_01';

export const TARGET_OPTIONAL_FEATURE_LIST_REF: Record<TargetMandatoryFeatureListRef, TargetOptionalFeatureListRef[]> = {
  essential_2021_04: [],
  premium_2021_04: ['idMatch_2021_04', 'wifi_2020_01', 'acs_2020_01'],
  enterprise_2021_04: [],
}

export interface IDiffFeatureList extends IFeatureListMultiPrice {
  features: Array<IFeature & { includedInCurrent: boolean; includedInTarget: boolean }>;
}

const get = (state: IAppState) => state.featureLists;

const getAll = createSelector(get, state => state.value);
const getTargetFeatureLists = createSelector(getAll, featureLists =>
  featureLists.filter(fl => fl.version === TARGET_VERSION)
);
const getTargetMandatoryFeatureLists = createSelector(getTargetFeatureLists, featureLists =>
  featureLists.filter(fl => fl.type === 'BASE')
);
const getTargetOptionalFeatureLists = createSelector(getTargetFeatureLists, featureLists =>
  featureLists.filter(fl => fl.type !== 'BASE')
);

export const FeatureListsSelectors = {
  getAll,
  getTargetFeatureLists,
  getTargetMandatoryFeatureLists,
  getTargetOptionalFeatureLists
};
